<template>
  <div v-if="open" :class="isPopUp ? 'confirmation-overlay' : ''">
    <div :class="isPopUp ? 'popup' : ''">
      <div class="rounded-2xl bg-surface-10">
        <div
          v-if="text != null"
          class="s2 m lg:d p-6 border-b border-surface-60 break-words"
        >
          {{ text }}
        </div>
        <div v-if="description != null" class="b1 m lg:d p-6">
          {{ description }}
        </div>
        <div class="flex justify-end border-t border-surface-60 p-6">
          <ButtonSecondaryMedium :label="closeActionText" @click="onCancel" />
          <div class="pl-2">
            <ButtonPrimaryMedium
              :alert="alert"
              :label="confirmActionText"
              @click="onConfirm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.confirmation-overlay {
  @apply fixed top-0 left-0 w-full h-full bg-neutral-60 bg-opacity-40 flex justify-center items-center z-50;
}

.popup {
  @apply bg-surface-10 rounded-xl max-w-[400px] drop-shadow;
}
</style>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    confirmActionText: {
      type: String,
      required: true,
    },
    closeActionText: {
      type: String,
      required: false,
      value: "Vazgeçin",
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    isPopUp: {
      type: Boolean,
      required: false,
      value: false,
    },
    alert: {
      type: Boolean,
      required: false,
    },
    open: {
      type: Boolean,
      value: true,
    },
  },
};
</script>
